@import '../node_modules/mini.css/dist/mini-dark.min.css';
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@font-face {
  font-family: 'Terminus';
  src: url('./fonts/terminus.ttf') format('truetype');
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}

html, * {
  font-family: "Terminus", monospace;
  font-weight: 400;
  font-style: normal;
}

p {
  padding-bottom: 1em;
}

img {
  vertical-align: middle;
  object-fit: contain;
}

.header {
  font-size: 5vw;
  padding-bottom: 1em;
}

.dark-button,
.dark-button:focus{
  background-color: #111 !important;
  color: rgb(246, 246, 246) !important;
}

.resume-button{
  justify-self: right;
}


.dark-button:hover{
  background-color: rgb(246, 246, 246) !important;
  color: #111 !important;
}

.section {
  padding-top: 5em;
  padding-bottom: 5em;
}

.image-container {
  display: flex;
}

.intro-container {
  height:95vh;
  padding-top: 0;
  padding-bottom: 0;
  scroll-margin: 56px;
  display: flex;
  align-items: center;
}

.intro {
  font-size: 5vw;
  padding-top: 0;
}

.indented {
  text-indent: 3em;
}

.social-icon {
  margin-right: 5px;
}
